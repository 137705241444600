:root {
  @apply light;
}

.dark {
  --color-bg-primary: #1a1a2e;
  --color-bg-secondary: #1a1a2e;
  --color-text-primary: #f7fafc;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #1682fc;
  --color-primary: #1682fc;
  --color-dark: #073492;
  --color-light: #72c1fe;
  --color-border-primary: #2d3748;
  --color-border-secondary: #4a5568;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #edf2f7;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #1682fc;
  --color-primary: #1682fc;
  --color-dark: #073492;
  --color-light: #72c1fe;
  --color-border-primary: #edf2f7;
  --color-border-secondary: rgb(215, 217, 215);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

a {
  text-decoration: none;
}
html {
  font-size: 14px;
}

#root,
body,
html {
  height: 100%;
  user-select: none;
}

#root {
  display: flex;
  flex-direction: column;
}

.split {
  display: flex !important;
  flex-direction: row !important;
}

.gutter {
  background-color: #e8e8e8 !important;
  background-repeat: no-repeat !important;
  background-position: 50% !important;
}

.gutter.gutter-horizontal {
  cursor: col-resize !important;
  width: 3px !important;
}

.folder-list {
  width: 100% !important;
}

.active {
  background-color: #c5c5c5;
  color: #000;
}
.Resizer.vertical:after {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.Resizer {
  background: #000;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 1px;
  margin: -5px 0;
  cursor: row-resize;
  width: 100%;
}

.Resizer.vertical {
  width: 0.5px;
  margin: 0 -5px;
  cursor: col-resize;
}

.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* dynamic tabs styles - start */

#tabs-ui {
  display: -webkit-box;
  padding: 6px 10px 0 10px;
}

#tabs-ui li {
  -webkit-box-flex: 1;
  padding: 10px 15px 6px 15px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
  min-width: 0px;
  width: 50px;
  text-overflow: ellipsis;
}
#tabs-ui p {
  overflow: hidden;
  max-width: 150px;
  min-width: 0px;
  text-overflow: ellipsis;
}

/* dynamic tabs styles - end */

/* react-select styles - start */

.css-qbdosj-Input > input,
.css-qbdosj-Input > input:focus {
  box-shadow: none !important;
}

.css-1n6sfyn-MenuList {
  padding: 0px !important;
}
#react-select-3-input {
  border: 0px !important;
  box-shadow: none !important;
}

/* auto-generate-doc start */

#auto-generate-doc th {
  text-align: left;
}
#auto-generate-doc th em {
  font-weight: 500;
}
#auto-generate-doc h2 {
  border-bottom: none;
}

/* auto-generate-doc end */

/* bulk-edit-editor start */

#bulk-edit-editor canvas {
  display: none !important;
}

/* bulk-edit-editor end */
