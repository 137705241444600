.tags-input-container {
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tag-item {
  background-color: var(--color-border-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
}
.tag-item .close {
  height: 15px;
  width: 15px;
  background-color: var(--color-text-secondary);
  color: var(--color-bg-primary);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  border: none;
  outline: none;
}
